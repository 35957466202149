import React, { Component } from 'react'
import { Map, TileLayer, Marker, Tooltip } from 'react-leaflet'
import PropTypes from 'prop-types'
import styled from 'styled-components';

const Container = styled.div`
  .leaflet-container {
    height: 400px;
    width: 100%;
  }
  strong {
      font-weight: bold;
  }
`

export default class LeafletMap extends Component {

  constructor() {
    super();
  }

  static propTypes = {
    /** Latitude and Longitude of the map centre in an array, eg [51, -1] **/
    position: PropTypes.array,

    /** Initial zoom level for the map (default 13) **/
    zoom: PropTypes.number,

    /** If set, will display a marker, which when clicked will display this text **/
    markerText: PropTypes.string
  }

  //Maya's Hundesalon, Erholung 8, 31234 Edemissen-Alvesse

  static defaultProps = {
    position: [52.397674, 10.322948],
    zoom: 13,
    markerText: "<strong>Maya's Hundesalon</strong>Erholung 8<br/>31234 Edemissen-Alvesse",
  }

  // <Popup>
  //  dangerouslySetInnerHTML={{ __html: this.props.markerText }}
  // </Popup>

  render() {
    if (typeof window !== 'undefined') {
      return (
        <Container>
          <Map center={this.props.position} zoom={this.props.zoom}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {this.props.markerText !== "" &&
              <Marker position={this.props.position}>
                <Tooltip permanent>
                  {(<span><strong>Maya's Hundesalon</strong><br />Erholung 8<br />31234 Edemissen-Alvesse</span>)}
                </Tooltip>
              </Marker>
            }
          </Map>
        </Container>
      )
    }
    return null
  }
}
